define('ember-contextual-back/mixins/contextual-back', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    maxBuffer: 10,

    goingBack: false,

    transitions: Ember.computed(function () {
      return Ember.A();
    }),

    pushTransition: function pushTransition(transition) {
      // replaceWith transitions are not pushed into the history stack
      // Need to ensure that the urlTransitions and transitions stacks
      // are consistent with window history
      if (transition.urlMethod !== 'replace') {
        if (this.transitions.length === this.maxBuffer) {
          this.transitions.shift();
        }
        this.transitions.pushObject(transition.targetName);
      }
    },


    actions: {
      willTransition: function willTransition(transition) {
        var _this = this;

        // willTransition is called before the transition urlMethod is set
        // HACK: wait until after routerTransitions have occured to ensure we know the urlMethod
        Ember.run.schedule('routerTransitions', function () {
          if (_this.transitions.length > 1) {
            var previousTransition = _this.transitions[_this.transitions.length - 2];

            // Native/Implemented back button has been used
            if (transition.targetName === previousTransition) {
              // Remove the previous transition
              _this.transitions.popObject();
            } else {
              _this.pushTransition(transition);
            }
          } else {
            _this.pushTransition(transition);
          }
        });
        return true;
      },
      back: function back() {
        if (this.transitions.length > 1) {
          window.history.back();
        } else {
          this.transitionTo('application');
        }
      }
    }

  });
});