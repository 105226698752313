define("ember-service-worker-update-notify/components/service-worker-update-notify", ["exports", "ember-service-worker-update-notify/templates/components/service-worker-update-notify"], function (_exports, _serviceWorkerUpdateNotify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _serviceWorkerUpdateNotify.default,
    tagName: '',
    serviceWorkerUpdateNotify: Ember.inject.service(),
    hasUpdate: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this._updateHandler = function () {
        return _this.set('hasUpdate', true);
      };

      this.serviceWorkerUpdateNotify.on('update', this._updateHandler);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.serviceWorkerUpdateNotify.off('update', this._updateHandler);
    }
  });

  _exports.default = _default;
});