define("ember-cli-segment/services/segment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Service.extend({
    _disabled: false,
    _defaultPageTrackDisabled: false,
    _defaultIdentifyUserDisabled: false,
    _calledPageTrack: false,
    init: function init() {
      this._super.apply(this, arguments);

      var isFastBoot = typeof FastBoot !== 'undefined';

      if (!this.hasAnalytics() && this.config && this.config.environment !== 'test' && !isFastBoot) {
        (false && Ember.warn('Segment is not loaded yet (window.analytics)', false, {
          id: 'ember-cli-segment.analytics-not-loaded'
        }));
      }

      if (this.config && this.config.segment) {
        var _this$config$segment = this.config.segment,
            defaultPageTrack = _this$config$segment.defaultPageTrack,
            defaultIdentifyUser = _this$config$segment.defaultIdentifyUser,
            enabled = _this$config$segment.enabled;
        this.set('_defaultPageTrackDisabled', defaultPageTrack === false);
        this.set('_defaultIdentifyUserDisabled', defaultIdentifyUser === false);
        this.set('_disabled', enabled === false);
      }
    },
    hasAnalytics: function hasAnalytics() {
      return !!(window.analytics && _typeof(window.analytics) === 'object');
    },
    isEnabled: function isEnabled() {
      return !this.get('_disabled');
    },
    enable: function enable() {
      this.set('_disabled', false);
    },
    disable: function disable() {
      this.set('_disabled', true);
    },
    isPageTrackEnabled: function isPageTrackEnabled() {
      return !this.get('_defaultPageTrackDisabled');
    },
    enableDefaultPageTrack: function enableDefaultPageTrack() {
      this.set('_defaultPageTrackDisabled', false);
    },
    disableDefaultPageTrack: function disableDefaultPageTrack() {
      this.set('_defaultPageTrackDisabled', true);
    },
    isIdentifyUserEnabled: function isIdentifyUserEnabled() {
      return !this.get('_defaultIdentifyUserDisabled');
    },
    enableDefaultIdentifyUser: function enableDefaultIdentifyUser() {
      this.set('_defaultIdentifyUserDisabled', false);
    },
    disableDefaultIdentifyUser: function disableDefaultIdentifyUser() {
      this.set('_defaultIdentifyUserDisabled', true);
    },
    log: function log() {
      if (this.config && this.config.segment && this.config.segment.LOG_EVENT_TRACKING) {
        console.info('[Segment] ', arguments); // eslint-disable-line no-console
      }
    },
    trackPageView: function trackPageView() {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.page.apply(this, arguments);
        this.set('_calledPageTrack', true);
        this.log('trackPageView', arguments);
      }
    },
    trackEvent: function trackEvent(event, properties, options, callback) {
      if (this.isEnabled() && this.hasAnalytics()) {
        this.checkPageTrackCalled();
        window.analytics.track(event, properties, options, callback);
        this.log(event, properties, options);
      }
    },
    identifyUser: function identifyUser(userId, traits, options, callback) {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.identify(userId, traits, options, callback);
        this.log('identifyUser', traits, options);
      }
    },
    identifyGroup: function identifyGroup(groupId, traits, options, callback) {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.group(groupId, traits, options, callback);
        this.log('identifyGroup', traits, options);
      }
    },
    // reset group, user traits and id's
    reset: function reset() {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.reset();
        this.log('reset');
      }
    },
    group: function group(groupId, traits, options, callback) {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.group(groupId, traits, options, callback);
        this.log('group', traits, options);
      }
    },
    aliasUser: function aliasUser(userId, previousId, options, callback) {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.alias(userId, previousId, options, callback);
        this.log('aliasUser', userId, previousId, options);
      }
    },

    /**
     * Logs warning into console if trackPageView method wasn't called before tracking event
     *
     * @see https://segment.com/docs/sources/website/analytics.js/#page
     */
    checkPageTrackCalled: function checkPageTrackCalled() {
      (false && Ember.warn('[ember-cli-segment] You should call trackPageView at least once ' + 'before tracking events: ' + 'https://segment.com/docs/sources/website/analytics.js/#page', this.get('_calledPageTrack'), {
        id: 'ember-cli-segment.must-call-page'
      }));
    }
  });

  _exports.default = _default;
});