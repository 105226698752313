define('ccon-common/components/carts-list/component', ['exports', 'ccon-common/components/carts-list/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    sortLabels: null,

    init: function init() {
      this._super();
      this.set('sortLabels', {
        "key": "Code",
        "total": "Total"
      });
    }
  });
});